import { forwardRef } from 'react';
import type * as React from 'react';
import { IconButton, SnackbarContent, styled } from '@mui/material';
import type { SnackbarContentProps, SnackbarCloseReason } from '@mui/material';
import { DeleteIcon } from '@/components';

export type MessageSeverity = 'info' | 'success' | 'warning' | 'error';

type StyledSnackbarContentProps = SnackbarContentProps & {
    severity: MessageSeverity;
};
const StyledSnackbarContent = styled(SnackbarContent, {
    shouldForwardProp: (propName) => propName !== 'severity',
})<StyledSnackbarContentProps>(({ theme, severity }) => ({
    fontWeight: 'bold',
    flexWrap: 'nowrap',
    width: '100%',
    borderRadius: 8,
    boxSizing: 'border-box',
    boxShadow: '3px 3px 8px rgba(0,0,0,0.5)',
    backgroundColor:
        severity === 'info'
            ? theme.bino.color.white
            : severity === 'success'
              ? theme.bino.color.darkGreen
              : severity === 'warning'
                ? theme.bino.color.orange
                : severity === 'error'
                  ? theme.bino.color.red
                  : theme.bino.color.black,
    color: severity === 'info' ? theme.bino.color.text : theme.bino.color.white,
    '& .MuiSnackbarContent-message': {
        padding: 24,
        fontSize: 18,
        '& a': {
            color: theme.bino.color.white,
        },
    },
    [theme.breakpoints.down('sm')]: {
        borderRadius: 0,
        '& .MuiSnackbarContent-message': {
            padding: 8,
            fontSize: 16,
        },
    },
}));

type ContentProps = StyledSnackbarContentProps & {
    handleClose(event: React.MouseEvent, reason?: SnackbarCloseReason): void;
};

const Content = forwardRef<HTMLDivElement, ContentProps>(
    (props, forwardedRef) => {
        const {
            message,
            severity = 'info',
            classes,
            handleClose,
            ...otherProps
        } = props;
        return (
            <StyledSnackbarContent
                ref={forwardedRef}
                message={message}
                severity={severity}
                onClick={(event) => {
                    if ((event.target as HTMLAnchorElement).href) {
                        handleClose(event);
                    }
                }}
                {...otherProps}
                action={
                    <IconButton
                        key="close"
                        aria-label="Close"
                        color="inherit"
                        onClick={handleClose}
                        size="large"
                    >
                        <DeleteIcon size={24} />
                    </IconButton>
                }
            />
        );
    },
);

export default Content;
