/**
 * @generated SignedSource<<e91edc8d96d94226babf918eb9c7cef3>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type TopBar_project$data = {
  readonly " $fragmentSpreads": FragmentRefs<"PortfolioBreadcrumbs_project">;
  readonly " $fragmentType": "TopBar_project";
};
export type TopBar_project$key = {
  readonly " $data"?: TopBar_project$data;
  readonly " $fragmentSpreads": FragmentRefs<"TopBar_project">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "TopBar_project",
  "selections": [
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "PortfolioBreadcrumbs_project"
    }
  ],
  "type": "Project",
  "abstractKey": null
};

(node as any).hash = "05e62bdf75e5ab9d02289463f6e31c83";

export default node;
