import { Outlet, useLoaderData } from 'react-router-dom';
import type { PreloadedQuery } from 'react-relay';
import { usePreloadedQuery } from 'react-relay';
import { PageLayout, TopBar } from '@/components/Page';
import { MainMenu } from '@/components/MainMenu';
import { useOnlyMyProjects } from '../utils/useOnlyMyProjects.ts';
import type { TeamPageLayoutRouteQuery } from './TeamPageLayoutRouteQuery.ts'; // useTeamRouteContext();
import { teamPageLayoutRouteQuery } from './TeamPageLayoutRouteQuery.ts';

// useTeamRouteContext();
export type TeamPageLayoutRouteProps = { [k: string]: never };
export const TeamPageLayoutRoute = (_props: TeamPageLayoutRouteProps) => {
    const data = useLoaderData() as TeamPageLayoutRouteWithDataProps;
    return <TeamPageLayoutRouteWithData queryRef={data.queryRef} />;
};

type TeamPageLayoutRouteWithDataProps = {
    queryRef: PreloadedQuery<TeamPageLayoutRouteQuery>;
};
const TeamPageLayoutRouteWithData = (
    props: TeamPageLayoutRouteWithDataProps,
) => {
    const data = usePreloadedQuery(teamPageLayoutRouteQuery, props.queryRef);
    const { team, pqiUser } = data;

    // Init only my projects
    useOnlyMyProjects({ team, pqiUser });
    return (
        <PageLayout
            topBar={<TopBar project={null} team={team} pqiUser={pqiUser} />}
            renderNavigation={(isMobile) => (
                <MainMenu mobile={isMobile} team={team} pqiUser={pqiUser} />
            )}
        >
            <Outlet />
        </PageLayout>
    );
};
